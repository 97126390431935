@font-face {
  font-family: SalesForceSans;
  src: url("./assets/fonts/SalesforceSans-Regular.woff");
  font-stretch: normal;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SalesForceSans;
  src: url("./assets/fonts/SalesforceSans-Bold.woff");
  font-stretch: normal;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: SalesForceSans;
  src: url("./assets/fonts/SalesforceSans-Light.woff");
  font-stretch: normal;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: AvantGarde-Demi;
  src: url("./assets/fonts/AVANTGA2.woff");
  font-stretch: normal;
  font-weight: 700;
  font-style: normal;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SalesForceSans;
}

.masterPage {
  height: 100%;
  width: 100%;
}

.homepageWidth {
  max-width: 1600px;
  width: 95%;
  margin: auto;
}

.mainWidth {
  max-width: 1284px;
  width: 95%;
  margin: auto;
}

.centeringDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dividingDiv {
  display: flex;
  justify-content: space-between;
}

.dividedRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.w-100{
  width: 100%;
}
.halfWidth {
  width: 47%;
}
.threeCol {
  width: 32%;
}
.fourCol {
  width:24%;
}
.mt-1{
  margin-block-start: 1rem;
}


.flag {
  width: 18px;
  height: 16px;
}

.link-button {
  color: #0b827c;
  cursor: pointer;
  width: max-content;
  margin: 0;
  padding: 0;
  transition: 0.3s;
  text-decoration: none;
}

.link-button:hover {
  text-decoration: underline;
}

.h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 60px;
}

.h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 68px;
}

.h3 {
  font-style: normal;
  font-size: 26px;
  line-height: 39px;
}
.headingColor{
  color: #032d60 !important;
}

.bigHeadingStyle {
  font-style: normal;
  font-size: 38px;
  font-weight: 700;
  line-height: 51px;
  color: #032d60;
}

.lightHeadingStyle {
  font-style: normal;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  color: #181818;
}

.newBoldHeadingStyle {
  font-style: normal;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: #181818;
}

.newHeadingStyle {
  font-style: normal;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  color: #181818;
}

.biggerHeadingStyle {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #181818;
}

.mediumHeadingStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  color: #181818;
}

.headingStyle {
  font-size: 16px;
  font-weight: 700;
  color: #181818;
  line-height: 24px;
  margin-block-end: 26px;
}

.xsmallHeadingStyle {
  font-size: 12px;
  line-height: 140%;
  font-weight: 600;
  color: #181818;
  margin-block-end: 15px;
}
.smallHeadingStyle {
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  color: #181818;
  margin-block-end: 15px;
}

.bigRegularText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #181818;
}

.regularText {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #181818;
}

.smallRegularText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #181818;
}

.boldText {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #181818;
}

.standardText {
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  color: #181818;
}

.thinText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #181818;
}

.biggerText {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #181818;
}

.smallBoldText {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #181818;
}
.xxsmallText {
  font-size: 8px;
  font-weight: 400;
  color: #181818;
}
.smallText {
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
  color: #181818;
}

.imageStyles {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.inputErrorStyles {
  font-weight: 600;
  font-size: 13px;
  color: #c63235;
  margin-block-start: 2px;
}

.headerBarDiv {
  width: 100%;
  height: 72px;
  position: absolute;
  z-index: 7;
}

.page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.pageFade-appear,
.pageFade-enter {
  opacity: 0;
  z-index: 1;
}
.pageFade-appear-active,
.pageFade-enter.pageSlider-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.pageFade-exit {
  opacity: 1;
}
.pageFade-exit.pageSlider-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background: transparent !important;
}

.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #0b827c !important;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #0b827c !important;
  background-color: #0b827c !important;
}

.rs-picker-value-count {
  background-color: #0b5cab !important;
}

/* Toggle Button */
.customPicker > .rs-btn.rs-btn-default.rs-picker-toggle.active {
  border-color: #00a3e0 !important;
  background-color: #e9f5fd !important;
}

.errorPicker > .rs-picker-toggle.rs-btn {
  border-color: #c63235;
  background-color: #f7e2e1 !important;
  transition: background-color ease-in-out 0.3s;
}

.rs-picker-toggle.rs-btn {
  padding-block: 9px !important;
  border-radius: 5px !important;
  border-color: #d8d8d8;
}

.defaultInput > a,
.errorInput > a {
  border: none !important;
}

/* SelectPicker, DatePicker, CheckPicker*/
.rs-picker-toggle-wrapper {
  height: 38px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-block: 8px !important;
}

.cursor-pointer{
  cursor: pointer;
}


.rs-modal-open {
  .rs-picker-menu{
  z-index: 9999!important;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2561px) {
  .sidebarFilterInnerContent{
    width: 380px !important;
  }
  .sidebarFilter {
    width: 38% !important;
  }
  .searchTrucksPageContent {
    width: 62% !important;
  }
  
  .searchTrucksPageInnerContent {
    width: 54% !important;
  }
}
@media screen and (max-width: 2560px) and (min-width: 1920px) {
  .sidebarFilterInnerContent{
    width: 380px !important;
  }
  .sidebarFilter {
    overflow: hidden !important;
    width: 34% !important;
  }
  .searchTrucksPageContent {
    width: 66% !important;
  }
  .width-95{
    width: 98% !important;
  }
  .width-100{
    width:  100% !important;
  }
  .searchTrucksPageInnerContent {
    width: calc(100% - 400px) !important;
  }
}

/*FILTER*/

@media screen and (min-width: 2048px) and (max-width: 2560px) {
  .sidebarFilterInnerContent {
    .headingStyle {
      font-size: 20px !important;
    }
    label {
      font-size: 17px !important;
    }
    input {
      font-size: 20px !important;
    }
    .datePickerComp > div > a {
      font-size: 17px !important;
    }
    .datePickerComp {
      margin-top: 2px !important;
      span {
        font-size: 17px !important;
      }

      .rs-picker-toggle-wrapper {
        height: 43px !important;
      }
    }
    .filterTagPicker {
      .rs-tag-text{
        font-size: 14px !important;
    }
      min-height: 43px !important;
      a > span {
        font-size: 20px !important;
      }
    }
    .rs-dropdown-toggle{
      font-size: 20px !important;
    }
    label>span{
      font-size: 20px !important;
    }
    .rs-checkbox-checker>label{
      font-size: 20px !important;
    }
    .smallHeadingStyle{
      font-size: 17px !important;
    }
    button{
      font-size: 20px !important;
      min-width: 120px !important;
    }
  }
}



/*LIST PAGE*/

@media screen and (min-width: 2048px) and (max-width: 2560px){
  .listScreen{
    width: 99% !important;
  }
  .searchTrucksPageInnerContent{
    .headingStyle{
      font-size: 22px !important;
    }
    .headingStyle>p{
      font-size: 18px !important;
    }
    .filterButtons{
      font-size: 22px !important;
      padding: 16px 6px !important;
    }
    .rs-pagination-xs > li > a,.customPagination,.rs-table-pagination-length-menu .rs-picker-toggle{
      font-size: 16px !important;
    }
    
  }
  .truckResultCard{
    .vehicleLogo{
      .imageStyles{
        // width: 100% !important;
        // height: 100% !important;
      }
      width: 90px!important;
      height: 90px !important;
    }
    .boldText{
      font-size: 16px !important;
    }
    .card-object-value{
      font-size: 18px !important;
    }
    .smallText,.smallRegularText{
      font-size: 16px !important;
    }
    .actionButton{
      font-size: 18px !important;
      margin-left: 5px !important;
    }
    .standardText{
      svg{
        width: 16px !important;
        height: 16px !important;
      }
      font-size: 16px !important;
    }
    .smallBoldText{
      font-size: 16px !important;
    }
    .thinText{
      font-size: 14px !important;
    }
    .rs-nav-item-content{
      font-size: 18px !important;
    }
    .tabbedNav{
      svg{
        width: 22px !important;
        height: 22px !important;
      }
    }
  }
}




@media screen and (min-width: 2560px) {
  .sidebarFilterInnerContent {
    .headingStyle {
      font-size: 24px !important;
    }
    label {
      font-size: 20px !important;
    }
    input {
      font-size: 22px !important;
    }
    .datePickerComp > div > a {
      font-size: 20px !important;
    }
    .datePickerComp {
      margin-top: 2px !important;
      span {
        font-size: 20px !important;
      }

      .rs-picker-toggle-wrapper {
        height: 43px !important;
      }
    }
    .filterTagPicker {
      .rs-tag-text{
        font-size: 14px !important;
    }
      min-height: 43px !important;
      a > span {
        font-size: 22px !important;
      }
    }
    .rs-dropdown-toggle{
      font-size: 22px !important;
    }
    label>span{
      font-size: 22px !important;
    }
    .rs-checkbox-checker>label{
      font-size: 22px !important;
    }
    .smallHeadingStyle{
      font-size: 20px !important;
    }
    button{
      font-size: 22px !important;
      min-width: 120px !important;
    }
  }
}



@media screen and (min-width: 2560px) {
  .listScreen{
    width: 99% !important;
  }
  .searchTrucksPageInnerContent{
    .headingStyle{
      font-size: 24px !important;
    }
    .headingStyle>p{
      font-size: 20px !important;
    }
    .filterButtons{
      font-size: 24px !important;
      padding: 16px 6px !important;
    }
    .rs-pagination-xs > li > a,.customPagination,.rs-table-pagination-length-menu .rs-picker-toggle{
      font-size: 18px !important;
    }
    
  }
  .truckResultCard{
    .vehicleLogo{
      .imageStyles{
        // width: 100% !important;
        // height: 100% !important;
      }
      width: 90px!important;
      height: 90px !important;
    }
    .boldText{
      font-size: 18px !important;
    }
    .card-object-value{
      font-size: 20px !important;
    }
    .smallText,.smallRegularText{
      font-size: 18px !important;
    }
    .actionButton{
      font-size: 20px !important;
      margin-left: 5px !important;
    }
    .standardText{
      svg{
        width: 18px !important;
        height: 18px !important;
      }
      font-size: 18px !important;
    }
    .smallBoldText{
      font-size: 18px !important;
    }
    .thinText{
      font-size: 16px !important;
    }
    .rs-nav-item-content{
      font-size: 20px !important;
    }
    .tabbedNav{
      svg{
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
}





@media screen and (min-width: 2560px) {
.vehicle-chat-drawer-card{
  .smallText{
    font-size: 18px !important;
  }

  .smallHeadingStyle{
    font-size: 24px !important;
  }
  .smallBoldText {
    font-size: 18px !important;
  }

}
.vehicle-chat-card-drawer-body{
  height: calc(100% - 420px) !important;
  .xsmallHeadingStyle{
    font-size: 18px !important;
  }
  .standardText{
    font-size: 16px !important;
  }
}
.vehicle-chat-drawer {
  min-width:800px !important ;
  .boldText{
    font-size: 18px !important;
  }
}
.chatHeaderCardImg{
  width: 100px !important;
  height: 100px !important;
}
.chatHeaderCard>.smallHeadingStyle{
  width: calc(100% - 120px) !important;
}
}









@media screen and (min-width: 2560px) {
  .verticalLoadCard,.verticalTruckCard{
    .boldText{
      font-size: 14px !important;
    }
    .smallText{
      font-size: 14px !important;
    }
    .smallBoldText{
      font-size: 14px !important;
    }
    .smallRegularText{
      font-size: 16px !important;
    }
    .iconGroupItem{
      font-size: 12px !important;
    }
  }
}
.sidebarNavigationDiv {
  width: 40.2%;
  height: max-content;
  border-inline-end: 1px solid #f0f0f0;
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sidebarNavigation {
  width: 450px;
  height: max-content;
}
.editFormOuterDiv{
  border: 1px dashed #ddd;
  padding:10px ;
}
@media screen and (max-width: 1180px) {
  .sidebarNavigation {
    width: 95%;
  }
}

.truckRegistrationDiv {
  width: 59.8%;
  height: max-content;
  padding-block: 36px;
  padding-inline-start: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.truckRegistration {
 width: 95% !important;
  max-width: 795px;
  height: max-content;
  padding: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.my-profile-card {
  width: 95%;
  max-width: 795px;
  height: max-content;
  padding: 50px;
  border-radius: 5px;
}
.my-profile-card-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.my-profile-card-form-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.tooltip {
  position: fixed;
  width: 200px;
  padding: 10px;
  border: 1px solid darkgray;
  background-color: white;
}

.countriesClearButton {
  margin-block-start: 10px;
  margin-inline-start: 16px;
  cursor: pointer;
  width: max-content;
}

.countriesClearButton:hover {
  text-decoration: underline;
}

.routesDiv {
  width: 180px;
  height: max-content;
  max-height: 250px;
  margin-block: 4px 15px;
  margin-inline-start: 13px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #f5f5f5;
  border-radius: 5px;
}

.firstSelection {
  fill: #0b827c !important;
}

.svg-map__location {
  fill: #d0d1d3 !important;
}

.svg-map__location:hover {
  opacity: 0.7;
}

.svg-map__location[aria-checked="true"] {
  fill: #ef721d !important;
}

.fleetExtraInfos {
  width: 100%;
  height: 340px;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
}

.fleetExtraInfosCol {
  width: 50%;
  height: 100%;
  padding: 35px 25px;
}

.rs-table-bordered {
  border-color: #d8d8d8;
  border-block-start: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.jvectormap-container {
  width: 100%;
  height: 100%;
}

.jvectormap-tip {
  position: absolute;
  top: 0;
  padding-block: 5px;
  padding-inline: 10px;
  background-color: black;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
}

.responsiveRegisterStep{
  display: none;
}

@media screen and (max-width: 972px) {
.registerSideBar{
  display: none !important;
}

  .truckRegistrationDiv{
    display: flex;
    padding-block:0px !important;
    justify-content: center !important;
    align-items: center !important;
    padding-inline: 0px !important;
    width: 100%;
  }
  .truckRegistration{
    padding-top: 20px !important;
    width: 100%!important;
  }
  .responsiveRegisterStep{
    display: unset;
  }
  .responsiveRegisterStepOuterDiv{
    padding-block-end: 40px !important;
  }
}
@media screen and (max-width: 972px) {
  .dividedRow {
    flex-wrap: wrap ;
  }
  .halfWidth{
    margin-block-end: 10px;
    width:  100% !important;
  }
  .threeCol{
    width: 100% !important;
    margin-block-end: 15px;
    
  }
  .threeCol>div{
    margin-block-end: 0px !important;
    
  }
  .halfWidth>div{
    margin-block-end: 0px !important;
    
  }
  .dividedRow{
    margin-block-start: 0px !important;
  }

}

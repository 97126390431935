.blogAndNews {
  width: 100%;
  height: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsCard {
  width: 414px;
  max-width: 414px;
  padding: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newsCarouselDiv {
  width: 1300px;
  padding-inline-start: 10px;
}

@media screen and (max-width: 1365px) {
  .newsCarouselDiv {
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 928px) {

  .newsCarouselDiv {
    width: 85%;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
.newscard-img-div{
  height: auto !important;
}
  .newsCarouselDiv {
    width: 80%;
    margin: auto;
  }
}
@media screen and (max-width: 480px) {
  .blogAndNews{
    height: auto !important;
  }
.blogsnews-title{
  font-size: 30px  !important;
}
  .newsCarouselDiv {
    margin-block-start: 10px !important;
    margin-block-end: 50px !important;
    width: 75%;
    margin: auto;
  }
.newscard-content{
  height: 380px !important ;
}
  .newscard-date{
    font-size: 10px !important;
  }
  .newscard-title{
    font-size: 16px !important;
    line-height:24px !important
  }
  .newscard-description{
    font-size: 12px !important;
    line-height:24px !important
  }

}
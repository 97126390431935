.contactUs {
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: center;
}

.contactUsContent {
  width: 1370px;
  height: 100%;
  display: flex;
}


@media screen and (max-width: 3840px) and (min-width: 2560px) {
.contactUs{
  height: 37% !important;
}
}
@media screen and (max-width: 1630px) {
  .contactUsContent {
    width: 96%;
  }
}

.officesDiv {
  width: 275px;
  height: 100%;
}

.offices {
  width: 100%;
  /* Mozilla */
  height: -moz-calc(100% - 181px);
  /* WebKit */
  height: -webkit-calc(100% - 181px);
  /* Opera */
  height: -o-calc(100% - 181px);
  /* Standard */
  height: calc(100% - 181px);
  cursor: pointer;
  border: "none";
}

.office {
  min-height: 150px;
  width: 90%;
  height: auto;
  padding: 15px;
}

.officeLocation {
  /* Mozilla */
  width: -moz-calc(100% - 274px);
  /* WebKit */
  width: -webkit-calc(100% - 274px);
  /* Opera */
  width: -o-calc(100% - 274px);
  /* Standard */
  width: calc(100% - 274px);
}
.activeOffice{
  border-left: 1px solid #0B5CAB;
}

@media screen and (max-width:928px) {
  .contactUs{
    height: 100% !important;
  
  }
  .office{
    .headingStyle{
      font-size: 12px !important;
      margin-block:2px !important;
    }

  }
  .contactUsContent{
    border: 1px solid rgb(232, 232, 232);
    flex-direction: column ;
  }
  .officesDiv{
    width: 96% !important;
    height: auto !important;
    max-height: 330px !important;
    
    h2{
      padding-block: 30px  10px!important;
      font-size: 24px !important;
    }
  }
  .officeLocation{
    width: 96% !important;
    height: 300px;
  }

  .offices{
    height: 100% !important; 
  
  }
  .offices>div{
    display: flex;
    flex-direction: row;
  }
  .activeOffice{
    border-left: 0px !important;
    border-top: 1px solid #0B5CAB;

  }
}
.sidebarFilter {
  /*width: 24.065%;*/
  width: 32.65%;
  height: max-content;
  display: flex;
  justify-content: flex-end;
  border-inline-end: 1px solid #f0f0f0;
}

.sidebarFilterInnerContent {
  width: 304px;
  height: max-content;
  padding-block-start: 20px;
}
.mblockend-10{
  margin-block-end:20px;
}
.searchTrucksPageContent {
  /*width: 75.935%;*/
  width: 67.35%;
  margin-inline-start: -1px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  border-inline-start: 1px solid #f0f0f0;
}

.searchTrucksPageInnerContent {
  width: 934px;
  height: max-content;
  padding-block-start: 20px;
  z-index: 1;
}

@media screen and (max-width: 1400px) {
  .sidebarFilter {
    width: 24.065%;
    padding-inline-start: 10px;
  }
  .searchTrucksPageContent {
    width: 75.935%;
  }
  .sidebarFilterInnerContent {
    width: 100%;
  }
  .searchTrucksPageInnerContent {
    width: 100% !important;
  }
}

.filterButtons {
  width: max-content;
  padding-inline: 6px;
  height: 32px;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
}

.filterButtons:hover {
  opacity: 0.85;
  transition: 0.3s;
}

.searchResults {
  width: 100%;
  /* Mozilla */
  height: -moz-calc(100% - 70px);
  /* WebKit */
  height: -webkit-calc(100% - 70px);
  /* Opera */
  height: -o-calc(100% - 70px);
  /* Standard */
  height: calc(100% - 70px);
}

.actionButton {
  width: 148px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #0b827c;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.2s;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: #0b827c;
}

.actionButton:hover {
  border: 1px solid #0b827c;
  background-color: #0b827c !important;
  color: #fff !important;
}

.actionButtonDisabled {
  width: 148px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.2s;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  border: 1px solid #7a7a7a;
  color: #7a7a7a;
}

.markerDiv {
  cursor: pointer;
}

.checkbox-input
{
  margin-block-end: 19px;
}
.mobile-search-sidebarfilter-drawer{
  width: 300px !important;
   .rs-drawer-dialog>.rs-drawer-content{
    background-color: #f7f7fa !important;
   }
}
  
.search-truck-card-list-mobil{
  display: none;
}
.width-100{
  width: 100% !important;
}  .width-95{
  width: 98% !important;
}
@media screen and (max-width: 928px) 
{
  .searchTrucksPageContent{
    padding-inline-end: 0px !important;
  }
  .searchTrucksPageInnerContent{
    padding-block-start: 0px !important;
  }
  .truck-offer-modal-form-div>.dividedRow{
    flex-wrap: wrap;
  }

  .truck-offer-modal-form-div>.dividedRow>div{
  width: 100% !important;

}
  .search-innerScreenContent{
    justify-content: center !important;
  }
  .searchtruckpagecontent-main{
    width: 100% !important;
  }
  .resultsResponsive{
    position: fixed;
    padding-block: 10px;
    background-color: #fff;
    padding-inline: 20px;
    z-index: 1035 !important;
    border-bottom: 1px solid rgb(240, 240, 240);
  }
  .searchResults{
    margin-block-start: 80px;
    padding-inline: 20px;
  }
  .searchTrucksPageInnerContent>div{
    padding-inline:  0px !important;
    padding-block-start: 0.1px !important;
  }
  .searchTrucksPageContent{
    border-inline-start: 0px !important;
  }
}

@media screen and (max-width: 784px) {
.search-truck-card-list{
  display: none;
}
.search-truck-card-list-mobil{
  display: flex !important;
  gap:30px;
  flex-wrap: wrap;
  justify-content:center;
}
.searchResults{
  padding-inline: 0px;
}
.responsivefilterButtonsOuterDiv{
  display: none;
}
.search-truck-result
{margin-inline-start: 20px;}

.customPagination{
  justify-content: center !important;
  .rs-table-pagination-start{
    display: none !important;
  }
}  .rs-picker-toggle-value{
  max-width: 250px;
}
}
@media screen and (max-width: 340px) {
  .rs-picker-toggle-value{
    max-width: 200px;
  }
}

.rs-breadcrumb > li {
  font-size: 13px;
  font-weight: 400;
}

.rs-breadcrumb > li > a {
  color: #0b5cab !important;
}

.headerBreadcrumb {
  width: 100%;
  height: 73px;
  border-block-start: 1px solid;
  border-block-end: 1px solid;
}

@media screen and (max-width: 3840px) and (min-width: 2560px){
  .sidebarNavigationDiv {
    width: 42% !important;
  }

}
@media screen and (max-width: 2560px) and (min-width: 1920px){
  .sidebarNavigationDiv {
    width:37% !important;
  }
}
@media screen and (min-width: 1921px)
{
  .profileInnerScreenContent{
    height: 100% !important;
  }
  .my-profile-card {
    max-width: 1100px !important;
  }
  .sidebarNavigationDiv {
    height: 100% !important;
  }
    .headerBreadcrumb{
     a,span{
        font-size: 20px !important;
     }   
    }
    .sidebarNavigation-div{
      .newBoldHeadingStyle{
        font-size: 32px !important;
      }
      .mediumHeadingStyle {
        font-size: 24px !important;
      }
      .headingStyle {
        font-size: 24px !important;
        line-height: 30px !important;
      }
      .standardText{
        font-size: 20px !important;
      }
    }
    .detailedFormDesc{
      .mediumHeadingStyle {
        font-size: 24px !important;
      }
      .standardText{
        font-size: 20px !important;
      }
      button{
        font-size: 22px !important;
      }
    }
    .detailedFormDesc>div{
      max-width: 240px !important;
    }

    .my-profile-card{
      .dx-widget{
        font-size: 18px !important;
      }
      .biggerHeadingStyle{
        font-size: 24px !important;
        line-height: 34px !important;
      }
      .smallRegularText{
        font-size: 20px !important;
        line-height: 20px !important;
      }
      .newBoldHeadingStyle{
        font-size: 32px !important;
        line-height: 34px !important;
      }
      .regularText{
        font-size: 24px !important;
        line-height: 24px !important;
      }       
      label{
        font-size: 18px !important;
      }
      input{
        font-size: 20px !important;
      }
      .defaultInput>a{
        span{
          font-size: 18px !important;
        }
      }
      button{
        font-size: 20px !important;
      }
    }
    .rs-picker-select-menu-item{
      font-size: 18px !important;
    }
    .my-profile-card-form-row>form{p{
      font-size: 18px !important;
    } }
    .company-details-advisor-card{
      font-size: 18px !important;
    }
    .destinationResponseButton{
      width: 300px !important;
      font-size: 20px !important;
    }
    .dx-overlay-content{
      width: 40% !important;
    }
    .dx-toolbar-label
    {
      
      font-size: 24px !important;
    }
    .dx-dialog-message,.dx-button-content{
      
      font-size: 20px !important;
    }

    .my-profile-card{
      .rs-nav{
        a{
          font-size: 24px !important;
        }
      }
    }
    .rs-panel-title{
      font-size: 22px !important;
    }








    //******CONTACT US******************/////


    .contactusMenu{
      ul>li>a{
        font-size: 20px !important;
      }
    }
    .contact-info>p{
      font-size: 18px !important;
    }
    .contact-input{
      input{
        font-size: 20px !important;
      }
    }
    .contact-form{
      button,textarea{
        font-size: 20px !important;
      }
    }

    .faqsContent{
      .faq-title>h2{
        font-size: 26px !important;
      }
      .row-title-text{
        font-size:20px !important;
      }
      .row-content-text{
        
        font-size:18px !important;
      }
      
    }


    

    //******BLOG******************/////
    .blog-content{
      font-size: 18px !important;
    }
    .blogTitle{
      font-size: 32px !important;
    }
}

@media screen and (max-width: 1921px) and (min-width: 979px)
{
  .profileInnerScreenContent{
    .sidebarNavigationDiv {
      width: 32% !important;
    }
    .sidebarNavigation{
      width: 285px !important;
    }
    .profile-main-content-div{
      width: 68% !important;
    }
    .my-profile-card {
      max-width: 880px !important;
    }
  }
}
.field.only-date {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.datePickerRsuite:hover,
.datePickerRsuite:focus {
  background-color: transparent;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #0b5cab;
  border-color: #0b5cab;
}

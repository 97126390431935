.mobile-profile-offer-load-card{
    display: none;
}

@media screen and (max-width: 1200px) {
    .mobile-profile-offer-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .mobile-profile-offer-load-card{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .profile-offer-load-card{
        
    display: none !important;
    }.profile-offer-button-group{
        justify-content: center;
    }

}
@media screen and (max-width:550px) {
    .offer-card{
        width: 100% !important;
    }    
    .profile-truck-card-responsive{
        width: 200px !important;
    }
    
}
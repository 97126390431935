.rs-modal-wrapper {
  display: flex;
  align-items: center;
}

.modalRsuiteHeader > .rs-modal-header-close {
  color: white !important;
}

.modalComponentDiv {
  margin: 0 auto;
}

.rs-modal-content {
  padding: 0 !important;
  border-radius: 10px;
}

.modalRsuiteHeader {
  width: 100%;
  height: 100px;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
}

.modalRsuiteHeaderContent {
  width: 100%;
  padding-left: 40px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalRsuiteHeader-close {
  background-color: transparent;
  height: max-content;
}

.rs-modal-full {
  max-width: 1300px;
}

.rs-uploader-text .rs-uploader-trigger-btn,
.rs-uploader-picture-text .rs-uploader-trigger-btn {
  background: transparent !important;
  border: 1px dashed;
  border-color: #00000033;
  border-radius: 5px;
}

.rs-uploader-text
  .rs-uploader-trigger:not(.rs-uploader-trigger-disabled)
  .rs-uploader-trigger-btn:hover,
.rs-uploader-picture-text
  .rs-uploader-trigger:not(.rs-uploader-trigger-disabled)
  .rs-uploader-trigger-btn:hover {
  background-color: #f6f6f6 !important;
}

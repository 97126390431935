.authPage {
  width: 100%;
  height: 100%;
  display: flex;
}

.authPageImage {
  width: 50%;
  height: 100%;
  background-image: url("../../assets/images/auth-image.png"),
    url("../../assets/images/auth-background.png");
  background-position: center 58px, center center;
  background-repeat: no-repeat;
  background-size: 75%, auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.authContent {
  width: 50%;
  height: 100%;
  display: flex;
}

.loginContent {
  width: 445px;
  height: 86.44%;
  padding-block-start: 15.5%;
  margin-inline-start: 15%;
}

.forgotPasswordContent {
  width: 445px;
  padding-block-start: 15.5%;
  margin-inline-start: 15%;
}


@media screen and (max-width:928px) {
  .authContent{
    justify-content: center;
  }
.authPageImage{
  display: none !important;
}
.authContent{
  width:70% !important;
}
.authPage {
  align-items: center !important; 
  justify-content: center !important; 
}
.loginContent{
  margin-inline-start:0px !important;
  width: auto !important;
}
.forgotPasswordContent{
  
  margin-inline-start:0px !important;
}
}
@media screen and (max-height:990px)  {
  .authPageImage{
    background-size:70%, auto !important;
  }

}@media screen and (max-height:880px)  {
  .authPageImage{
    
  background-position: center center, center center !important;
    background-size:cover !important;
    background-image: 
      url("../../assets/images/auth-background.png");
  }

}
.aboutUs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exploreCard {
  width: 30.26%;
  min-width: 300px;
  height: 530px;
  margin-inline: 1.74% 1.74%;
  padding-block: 57px;
  padding-inline: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.exploreCard:hover {
  border-color: #0b5cab !important ;
  transition: 0.3s;
}

.openerPageDiv {
  width: 100%;
  height: 100%;
}
.actionCard-mobile {
  display: none;
  position: absolute;
  z-index: 1;
  bottom: 15%;
}
.actionCard-mobile-modal {
  display: none;
}
.openerPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.openerPageContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actionCard {
  width: auto;
  height: 420px;
  border-radius: 5px;
  padding-inline: 34px;
  padding-block: 45px 34px;
  box-shadow: 0 50px 50px #032d6016;
  border: 1px solid #f4f6f8;
  position: absolute;
  z-index: 1;
  bottom: 5%;

}

.mobile-homepage-buttons {
  display: none;
  justify-content: space-between;
  margin-block-start: 5px;
}
@media screen and (max-width: 928px) {
  .homepagebutton {
    padding-block: 9px !important;
    font-size: 14px !important;
  }
  .actionCard {
    padding-block: 10px;
  }
  .mobile-homepage-buttons {
    display: flex !important;
  }
}

@media screen and (max-height: 900px) {
  .homepage-carousel-item {
    padding-block-start: 30px !important;
  }
  .homepage-carousel-subtitle {
    font-size: 18px !important ;
  }
  .homepage-carousel-title {
    font-size: 20px !important ;
    margin-block-end: 0px !important;
  }
}

@media screen and (max-height: 670px) {
  .homepage-carousel-item {
    padding-block-start: 10px !important;
  }
  .homepage-carousel-subtitle {
    font-size: 14px !important ;
    text-align: center;
  }
  .homepage-carousel-title {
    font-size: 16px !important ;
    margin-block-end: 0px !important;
    text-align: center;
  }
}


@media screen and (max-width: 3840px) and (min-width: 2560px) and  (max-height: 2160px)   and (min-height: 1600px){
  .openerPage{
    max-height: 70vh !important;
  }
  .actionCard {
    bottom:42% !important;
  }
}

@media screen  and (max-width: 2560px) and (min-width: 1920px) and  (max-height: 1600px)   and (min-height: 1440px){
  .actionCard {
    bottom:25% !important;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1920px) and  (max-height: 1440px)   and (min-height: 1080px){
  .actionCard {
    bottom:25% !important;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1600px) and  (max-height: 1200px)   and (min-height: 1080px){
  .actionCard {
    bottom:23% !important;
  }
}






@media screen and (max-height: 670px) and (min-height: 0px) and (max-width: 800px) {
  .actionCard {
    bottom:2% !important;
  }
}


@media screen and (max-height: 700px) and (max-width: 1700px) {
  .actionCard{
    height: 370px !important;
    padding-block: 20px;
    li>a>div{
      margin-block-start: 0px !important;
    }
    li{
      height: 70px !important;
    }
  }
    
}
.pmverticalLoadCard {
  width: 200px !important ;
  height: 465px;
  background: #ffffff;
  border-radius: 10px;
  margin-inline: 15px;
  /* Drop Shadow */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  transition: 0.5s ease-in-out;
  margin-block-start: 10px;
}
.mobil-extraDetailsContent>.rs-nav.rs-nav-horizontal{
  white-space: unset !important;
}
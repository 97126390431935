.truckTypeCard {
  width: 136px;
  min-width: 136px;
  height: 158px;
  padding-block: 27px;
  padding-inline: 3px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
}

.truckTypeCard:hover {
  box-shadow: 0px 3px 50px #00000014;
}


.registerReponsiveStep > .rs-steps-item.rs-steps-item-status-process > .rs-steps-item-icon-wrapper{
background-color: #E6F7FE !important;
  color: white !important;
}
.registerReponsiveStep > .rs-steps-item > .rs-steps-item-icon-wrapper{
  border: 1px solid #0B5CAB !important;
  border-radius: 50%;
  color:#0B5CAB !important
}
.registerReponsiveStep > .rs-steps-item.rs-steps-item-status-finish > .rs-steps-item-icon-wrapper{
  background-color: #E5E9EF !important;
  border-color: #8095AF !important;
  }

.managerMessagebox {
  width: max-content;
  max-width: 60%;
  padding: 16px;
  border: 1px solid;
  border-radius: 21px;
  border-start-start-radius: 6px;
  margin-block: 10px;
  font-size: 13px;
}

.customerMessagebox {
  width: max-content;
  max-width: 60%;
  padding: 16px;
  border: 1px solid;
  border-radius: 21px;
  border-start-end-radius: 6px;
  margin-block: 10px;
  font-size: 13px;
}

.messageSentTime {
  width: max-content;
  margin-inline-start: auto;
  margin-block: 7px -7px;
}

@media screen and (min-width: 1921px)
{
    .mainWidth{
       max-width: 1600px !important;
    }
    .navbarloginButton{
        font-size: 22px !important;

    }
    .homepagebutton{
        font-size: 22px !important;
    }
    .navbarRegisterButton{
        font-size: 18px !important;
    }
     //******  NAVBAR  ******//
    .lg-navbar-menu{
        .rs-navbar-body>.rs-nav >ul{
            margin-block-end:-46px !important
        }
        .rs-navbar-body>.rs-nav >ul>li>a>span{
            font-size: 22px !important;
        }
        .rs-navbar-body>.rs-nav >ul>li>a{
            font-size: 22px !important;
        }
        
    .navbarDropdown {
        a{
            font-size: 22px;
        }
    }
    .navbarSelectPicker{
        a>span>img{
            width: 32px !important;
            height: 32px !important;
        }
    }

    }
    .navbarLanguageDropdownItem{
        font-size: 22px;
    }
    .navbarLanguageDropdownIcon>img{
        width: 32px !important ;
        height: 32px !important;
    }

 //******************************************************************************
    .actionCard{
        a>div>span{
            font-size: 20px !important;
        }
        input{
            font-size: 18px !important;
        }
        .datePickerComp>div>a{
            
            font-size: 18px !important;
        }
        .datePickerComp{
            margin-top: 2px !important;
            .rs-picker-toggle-wrapper{
                height: 43px !important;
            }
        }
    }
   //CAROUSEL//
   .homepage-carousel-title{
    font-size: 64px !important;
   } 
   .homepage-carousel-subtitle{
    font-size: 34px !important;
    line-height:1.2 !important;
   }

   .aboutUs>.bigHeadingStyle>p{
    font-size: 44px !important;
   }
   .aboutUs>div>span>p{
    font-size: 22px !important;
    line-height:1.2 !important;
   }

    //********************************************************************************************************************************************\\
   .homepageaboutusArticle{
    max-width: 1600px !important;
   }
    .exploreCardTitle{
    font-size: 32px !important;

   }
   .exploreCardDesc{
    font-size:24px !important;
    }
   .exploreCardButton{
    font-size: 32px !important;
    }


    //********************************************************************************************************************************************\\

    .blogsnews-title{
        font-size: 36px !important;
    }
    .blogsnews-subtitle{
        font-size:28px !important;
    }
    .newscard-date{
        font-size:18px !important;
    }
    .newscard-title{
        font-size:28px !important;
    }
    .newscard-description{
        font-size:22px !important;
        height: 150px !important;
        max-height: 150px !important;
    }
    .newscard-button{
        width: 150px !important;
        font-size:18px !important;
    }
    //********************************************************************************************************************************************\\
    .story>.bigHeadingStyle{
        font-size: 44px !important;
        
    }
    .storyItems{
        width: 1600px !important;
    }
    .storyItem{
        font-size: 24px !important;
    }
  //********************************************************************************************************************************************\\7
    .newsCarouselDiv{
    width: 1650px !important;
    }
    //********************************************************************************************************************************************\\
    .contactUsContent{
        width: 1600px !important;
    }
    .office{
        width: 65% !important;
        .headingStyle{
            font-size: 18px !important;
            img{
                width: 32px !important;
                height: 32px !important;
            }
        }
        .standardText{
            font-size: 18px !important;
        }
    }

    //********************************************************************************************************************************************\\
    .footerNavigationCol>p{
        font-size: 22px !important;
    }
    .footerNavigationCol>.standardText{
        font-size: 20px !important;
    }
    .footerNavigationOuterDiv{
        margin-left: 20px;
    }
    .footerNavigation{
        width: 1600px !important;
    }

    .footerEndContent>p{
        font-size: 24px !important;
    }
    .footerSelectPicker>a>span>span>img{
        
        width: 32px !important;
        height: 32px !important;
    }
    
}


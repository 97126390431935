@media screen and (min-width: 1921px) {
  .defaultInputRes>span{
    font-size: 18px !important;
  }
  .defaultInputRes{
    .defaultInput{
      height: 43px !important;
    }
    .defaultInput>a{
      font-size: 20px !important;
    }
  }

  }
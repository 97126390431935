.input-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
    border: 1px solid #c8c8c8
  }
  
  .icon {
    padding: 5px;
    text-align: center;
  }
  
  .input-field {
    background-color: transparent;
    width: 100%;
    border: 0px !important;
    outline: none;
  }
  
  .input-field:focus {
    border: 2px solid dodgerblue;
  }
  


  .datagridCheckBox{
    width: 120px !important;
    .rs-picker-value-item{
      font-size: 12px;
    }
  }
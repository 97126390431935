.overflownLeftDiv {
  display: flex;
  position: absolute;
  min-width: 30px;
  background-color: #000000cc;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.overflownRightDiv {
  display: flex;
  position: sticky;
  min-width: 30px;
  right: 0;
  background-color: #000000cc;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

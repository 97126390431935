.auctionPage{
    margin-block: 40px;
}

.auctionMenuItem{
    padding: 10px;
    border: 1px solid rgb(230, 230, 230);
    cursor: pointer;
}


.auctionMenuItemTitle{
    font-weight: 700;
    font-size: 16px;
    color: #181818;
}

.auctionMenuItem:hover,.activeMenuItem{
    border-color: #0B5CAB;
}

.auctionButton{}
.auctionCardList{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap:10px;
    margin-top: 10px;
}
.auctionCard{
    width:  100%;
    cursor: pointer;
    padding: 10px;
    border: 1px solid rgb(230, 230, 230);
}
.auctionCard:hover{

    border-color: #0B5CAB;
}
.auctionIconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px
}
.auctionCardColumn{
    p{
        text-align: center;
        margin-bottom: 0px;
    }
    p{
        margin-top:0px !important;
    }
}
.auctionCardColumnTitle{
    font-weight: 700;
}

.menuHeight{
    height: 230px;
  
}
.tableInputTextArea{
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}
.tenderQuestionSpan{
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
}
.auctiondetailMenuItem{
color: #181818;
    padding: 10px;
    border: 1px solid rgb(230, 230, 230);
}
.auctiondetailMenuItemDescription{
    font-size: 14px;
    padding: 10px;
    border-bottom: 1px solid rgb(230, 230, 230);
    border-left: 1px solid rgb(230, 230, 230);
    border-right: 1px solid rgb(230, 230, 230);
}
.minHeigth230{
    
    min-height: 230px;
}
.auctionTable{
    width: 100%;
    th,td{
        text-align: left;
        padding:5px 20px 0px 5px;
    }
}



.autionDetailFileItem{
    padding-top:5px ;
}
.autionDetailFileItem:hover{
    cursor: pointer;
    color: #0B5CAB;
    text-decoration: underline;
}


.auctionInfoCard{
    min-height: 120px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px ;
    border: 1px solid rgb(230, 230, 230);
    text-align: center;
}
.text-center{
    text-align: center;
}

.auctionButton{
    padding: 20px 30px;
    font-weight: 600;
    width: 100% ;
    background-color: #0B5CAB;
    color: white;
}
.auctionButton:hover{
    
    background-color: #0b5cab9e;
}
.auctionButtonSuccess{
    padding: 20px 30px;

    font-weight: 600;
    width: 100% ;
    background-color: green;
    color: white;
}
.auctionButtonSuccess:hover{
    
    background-color: green;
}

.gridInput{
    max-width: 100px !important;
}

.columnInput{
    border: 1px solid rgb(230, 230, 230);
    min-height: 28px;
    width: 100%;
    padding: 4px 2px;
}

.tabButton{
    cursor: pointer;
}
.tableButtonsPosition{
    display: flex;
    align-items: flex-end;
    min-height: 30px;
}

.questionTextArea{
    padding: 5px ;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: 50px !important;
    border-radius: 6px !important;
}
.questionTextArea:focus-visible{
    outline: 0px !important;
    border-color: #0B5CAB;
}
.questionListDiv{
    height:calc(100% - 30px);
}
.questionInputOuterDiv{
    height:50px ;
    display: flex;
    align-items: center;
}

.questionHeader{
    margin-bottom: 20px;
}
.questionContainer{
    margin-block-end: 10px;
    border: 1px solid rgb(230, 230, 230);
}
.tableInputDivForCurrency{

    width: 100%;
    height: 100%;
    gap:5px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableInputDiv{
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.tablebuttondiv{
    display: flex;
    height: 35px;
    justify-content: flex-end;
    align-items: center;
}
.tablebutton{
    width: max-content;
    min-width: 100px;
    padding: 15px 10px;
    right: 35px;
    
    font-size: 16px;
    font-weight: 400;
    color:white;
    background-color: #0B5CAB;
    line-height: 1px !important;
}
input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;   margin: 0;
}
.disabled{
    cursor: no-drop;
}
.tableInput{
    min-width: 50px;
    width: 70%;
    border: 1px solid#c8c8c8;
}
.tableInputError{
    border: 1px solid #c63235 !important;
    background-color: #f7e2e1 !important;
}
.termsInput{
}
.termsInput:hover{
cursor: pointer;
    color: #0B5CAB;
    text-decoration: underline
}
.tableInput:focus{
    outline: none !important
}
.borderedTable{
  border-collapse: collapse;
  width: 100%;
  tr:nth-child(even){background-color:#f2f2f259;}

  tr:hover 
  {

    background-color:#f0efef;
  }
  th {
      padding-top: 12px;
      padding-bottom: 12px;
      padding: 8px;
      text-align: center;
      font-weight: 400 !important;
      border: 1px solid#f0efef;
      background-color:#f0efef;
      border: 1px solid #ddd;
      color:#333
    }
    td{
        text-align: center;
        border-collapse: collapse;
      border-block: 1px solid #f0efef;
      border-inline: 1px solid #f0efef;
      padding: 8px;  
    }
   
} 



.questionOuterDiv{
    border: 1px solid #f0efef;
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
    border-radius: 6px;
    .questionDiv {
        padding: 10px;
        font-weight: 700;
        background-color: #f0efef;
    }
    .answerDiv{
        padding: 10px;
    }
}

.disabledNav{
    cursor: not-allowed !important;
    background-color: #e6e6e6;
}
.disabledNav:hover{
    border-color: #e6e6e6 !important;
}

@media screen and (max-width: 40em) {
    .searchTableRow{display: none !important;}
    .tableInputDiv{
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.sidebarNavigation {
  width: 100%;
  height: max-content;
}
.col-md-2 {
  width: 47%;
}
.col-md-1 {
  width: 100%;
}

.sidebarNavigationComponent {
  width: 100%;
  height: max-content;
  padding-inline-end: 7%;
}

.customSidenav {
  background-color: transparent;
}

.detailedFormDesc {
  width: 100%;
  height: max-content;
}

.documentsDescriptionCard {
  width: 100%;
  height: max-content;
  padding-block: 15px;
  display: flex;
}

.documentsDescriptionIcon {
  width: 87px;
  height: 87px;
  background: #f8adb033;
  border-radius: 10px;
  margin-inline: 5px 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documentsDescription {
  /* Mozilla */
  width: -moz-calc(95% - 92px);
  /* WebKit */
  width: -webkit-calc(95% - 92px);
  /* Opera */
  width: -o-calc(95% - 92px);
  /* Standard */
  width: calc(95% - 92px);
  padding-inline-end: 5px;
}

.rs-sidenav .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content {
  text-align: start;
  display: flex;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.rs-sidenav .rs-nav-item > .rs-nav-item-content {
  padding: 15px 12px;
}

.rs-nav-default
  .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled)
  > .rs-nav-item-content:hover,
.rs-nav-default
  .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled)
  > .rs-nav-item-content:focus {
  color: #095197;
  background-color: white;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY.styles_no-tabfocus__1HmyD {
  font-weight: 700;
  padding-inline-start: 1px;
}

.styles_row-content__QOGZd.row-content.styles_animate__3ecdr {
  padding-inline-start: 12px;
}

.sidebarNavigation-div > ul {
  margin-inline-start: 0px !important;
  padding-inline-start: 0px !important;
  list-style: none;
}
.sidebarNavigation-div > ul > li > span {
  color: #000000;
  cursor: pointer;
}
.sidebarNavigation-div > ul > li > span:hover {
  color: #37a75b;
}
.sidebarNavigation-div > ul > li > span:target {
  color: #37a75b;
}
.sidebarNavigation-div > ul > li > span:active {
  color: #37a75b;
}
.sidebarNavigation-div > ul > li > span > .rs-badge-independent {
  background-color: #0b827c;
  font-size: 12px;
  font-weight: 400px !important;
}

.progress-bar {
  padding-block-start: 5px;
  margin-inline-start: 0px;
  padding-inline-start: 0px;
  .rs-progress-line-inner {
    border-radius: 0px !important;
  }
  .rs-progress-line-outer {
    border-radius: 0px !important;
  }
  .rs-progress-line-bg {
    border-radius: 0px !important;
  }
}

.drag-drop-header {
  background-color: #fcfcfc;
  border: 1px solid #fcfcfc;
  padding-block: 12;
  padding-inline-start: 10;
  margin-block-end: 10;
}
.profile-header {
  background-image: url("../../../assets/images/profile-bg.png");
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 316px;
}

.active {
  color: #37a75b !important;
}
.dx-link {
  color: #00a3e0 !important;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  background-color: #f0efef;
  color: #000000;
}
.dx-fileuploader-wrapper {
  padding: 0px !important;
}
.pack-table {
  width: 40%;
}
.pack-table > tr > td,
.pack-table > tr > th {
  padding: 5px 10px 5px 5px;
  text-align: left;
}

.mobile-profile-sidenavbar-drawer {
  width: 280px !important;
  .rs-drawer-dialog > .rs-drawer-content {
    background-color: #fcfcfc !important;
  }
}

.mobile-profile-sidenavbar-content {
  display: none;
}

@media screen and (max-width: 1350px) {

  .profile-main-content-div > div {
    width: 100%;
  }
  .my-profile-card {
    position: unset !important;
    width: 98% !important;
  }
  .my-profile-card-form-row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .company-details-divider {
    display: none;
  }
  .company-details-form {
    width: 100% !important;
  }
}

@media screen and (max-width: 978px) {
  .profileCompanyDetailsOwnerCardContent{
text-align: center;
  }
  .my-profile-card {
    padding-block-start: 60px !important;
    position: unset !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .profile-main-content-div{
    padding-block: 0px !important;
    padding-inline-start: 0px !important;
  }
  .pack-table {
    width: 100%;
  }
  .myprofile-menu-outer-div {
    display: flex;
    justify-content: center;
  }
  .profile-sidebar-menu-name {
    font-size: 14px !important;
  }
  .headingStyle {
    font-weight: 500 !important;
  }
  .profile-sidebar-menu-status {
    font-size: 14px !important;
    margin-block-end: 0px !important;
  }
  .profile-sidebar-menu-account {
    margin-block-end: 0px !important;
    font-size: 14px !important;
  }
  .profile-sidebar-menu-info {
    margin: 0px !important;
  }
  .profile-sidebar-menu-img {
    width: 100px !important;
    height: 100px !important;
  }
  .profile-sidebarmenu-lg-size {
    display: none !important;
  }
  .profile-main-content-div {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }


  .mobile-profile-sidenavbar-content {
    display: block !important;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 700px) {
  .col-md-2 {
    width: 100%;
  }
}


@media screen and (max-width: 538px) {
  .my-profile-card{
    padding-inline: 30px !important;
  }
}

.mobile-profile-sidenavbar-content>.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover{
  background-color: #FAFCFE !important;
}
.mobile-profile-sidenavbar-content>.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus{
  background-color: #FAFCFE !important;
}

.mobile-profile-sidenavbar-content>.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle, .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle, .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle{
 color: #095197 !important;
 font-weight: 700;
}

.mobile-profile-sidenavbar-content>.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content, .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content, .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:hover, .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:hover, .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:focus, .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:focus{
  background-color: #FAFCFE !important; color: #095197 !important; font-weight: 700;
}
.content {
    min-height: 77px;
    height: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.divider-profile-truck-card                  {
    height: 50px;
    width: 1px;
    background-color: #e6e6e6;
    margin-inline: 5px;
  }
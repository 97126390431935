.homepage-nav {
  width: 100%;
  height: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid;
}
.display-none{
  display: none !important;
}
.display-none-drawer{
  .rs-drawer-wrapper{
    display: none;
  }
}
.display-none{
  display: none !important;
}
.nav-wrapper {
  width: 100%;
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
}
.register-modal{
  .modalRsuiteBody{
    max-height:700px !important;
  }
}
.navbarContentDiv {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customNavbar {
  overflow: visible !important;
}

.navbarDropdown > a {
  font-size: 15px;
  color: #215ca0 !important;
}

.rs-nav-item.login:hover {
  text-decoration: underline;
}

.customNavbar.rs-nav > ul {
  margin-block-end: -62px;
}

.registrationTypeDiv {
  display: flex;
}

.registrationTypeInnerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 34%;
  height: 300px;
  cursor: pointer;
  position: relative;
  margin-inline: 0px;
  border: 3px solid #fff;
  transition: 0.3s ease-in-out;
}

.registrationTypeInnerDiv:hover {
  border-color: #0b5cab;
  .signUpLabel {
    opacity: 1;
  }
}

.registrationType {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.3s ease-in-out;
  filter: grayscale(100%);
}

.signUpLabel {
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.registrationTypeNameDiv {
  position: absolute;
  z-index: 1;
  bottom: 40px;
}

.registrationTypeName {
  padding: 2px 15px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 0 15px 15px 15px;
}

.registrationTypeLeadAccount {
  background-image: url(../../assets/images/customer.png);
}

.registrationTypeLeadAccount:hover {
  filter: none;
}

.registrationTypeLeadTransporter {
  background-image: url(../../assets/images/shipper.png);
}

.registrationTypeLeadTransporter:hover {
  filter: none;
}

.registrationTypeCandidate_Forwarder {
  background-image: url(../../assets/images/forwarder.png);
}

.registrationTypeCandidate_Forwarder:hover {
  filter: none;
}

.registrationFromImage {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.registrationFromImageLeadAccount {
  background-image: url(../../assets/images/customer-form.png);
}

.registrationFromImageLeadTransporter {
  background-image: url(../../assets/images/shipper-form.png);
}

.registrationFromImageCandidate_Forwarder {
  background-image: url(../../assets/images/forwarder-form.png);
}

.registrationFormDiv {
  padding-block: 20px;
  padding-inline-end: 15px;
}

.rs-navbar.rs-navbar-subtle {
  background-color: transparent;
}

.rs-nav-item-content {
  font-size: 12px;
  font-weight: 700;
}

.navbarItem:hover,
.navbarItem:focus {
  text-decoration: none;
}

.rs-navbar.rs-navbar-subtle,
.rs-navbar-nav,
.rs-nav-item > .rs-nav-item-content {
  text-align: center;
}

.rs-nav-item-content {
  height: 100% !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #d8d8d8;
  background-color: #f7f7fa !important;
  color: #272c36 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #15214a;
}

.scrollableNav {
  color: #00000080;
}

.scrollableNav:hover {
  color: #000000;
  text-decoration: none;
}

.selectedScrollableNav {
  color: #000000 !important;
}

.navbarSelectPicker > a {
  border: none !important;
}

.custom-radiogroup > div > div > div > label > span {
  left: 5px !important;
}

.register-modal > div > .rs-modal-content > div > .modalRsuiteBody {
  height: auto ;
  min-height: 300px ;
}
//ZINDEX SELECT TOGGLE
.rs-picker-default .rs-picker-toggle{
  z-index: 0 !important;
}

.react-tel-input .country-list{
  max-height: 140px !important;
  z-index: 3 !important
  ;
  margin: 0px 0px 0px -1px;
  min-width:430px;
  width:100% !important
}
.react-tel-input .form-control{
  border: 1px solid #d8d8d8 !important;
  min-height: 38px !important;
}

.mobile-navbar-drawer{
  display: none !important;
  width: 220px !important;
 // display: none !important;
 .rs-drawer-dialog>.rs-drawer-content{
  background-color: #f7f7fa !important;
 }
}
.mobile-navbar-drawer-div{
  display: none !important;
}



@media screen and (max-width: 928px) {
  .mobile-navbar-drawer-div{
    display: block !important;
  }
  .mobile-navbar-drawer{

    display: block !important;
  }

  .lg-navbar-menu{
    display: none !important;
  }
}


.responseive-registerFormik{
  display: none;
  width: 100%;
}
.registerFormik{
  width: 100%;
}

@media screen and (max-width: 634px) {
  .responseive-registerFormik{
    display: unset !important;
    width: 100%;
  }
  .registerFormik{
    width: 100%;
    display: none;
  }
  
}

.mobileRegisterStep1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #F5F8FC;
  padding: 14px 18px;
  border-radius: 10px;
}
.mobileRegisterStep1img{
  background-color:#fff;
  padding: 17px 15px;
  border-radius: 10px;
}
.selectedAccount{
  background-color: #095197;
}



.registerModalInputZIndex{
  z-index: 1 !important;
}
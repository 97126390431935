

@media screen and (max-width:1384px) {
    .blog-content{
        width:98% !important;
    }
    
    }


@media screen and (max-width:928px) {
.blogs-sidenavigation{
    display: none !important;
}
.blog-outer-div{

    width:96% !important;
}
.blog-content{
    width:100% !important;
    img{
        width: 100% !important;
    }
}
}
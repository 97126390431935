.topHeaderBar {
  width: 100%;
  height: 45px;
}

.topHeaderBarContentDiv {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topHeaderBarContent {
  height: 100%;
  width: max-content;
  display: flex;
}

.topHeaderBarRightContent {
  height: 100%;
  width: max-content;
  display: flex;
  align-items: center;
  padding-block: 0;
  padding-inline: 15px;
  font-size: 12px;
  font-weight: 400;
}

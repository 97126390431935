.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
.rs-nav .rs-nav-item-active > .rs-nav-item-content,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:hover,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:focus,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active,
.rs-nav .rs-nav-item-active > .rs-nav-item-content:active {
  color: #032d60;
  font-weight: 700;
  overflow: visible;
  background-color: #ff904433;
}

.rs-nav-subtle.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rs-nav-subtle.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content::before {
  background-color: transparent;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff904433;
  margin-inline: auto;
  margin-block-end: -10px;
}

.rs-nav-subtle.rs-nav-horizontal .rs-nav-waterline {
  display: none;
}

.customNav.rs-nav > ul {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.customNav.rs-nav-item-content {
  height: 50px;
  display: flex !important;
  align-items: center !important;
}

.customNav {
  display: flex;
  width: 100%;
}

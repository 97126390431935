.defaultInput {
  border: 1px !important;
  border-style: solid !important;
  border-color: #d8d8d8 !important;
  border-radius: 5px !important;
  background-color: #fff;
}

.defaultInput.rs-input-group-focus {
  border-color: #00a3e0 !important;
  background-color: #e9f5fd !important;
  transition: background-color ease-in-out 0.3s;
}

.errorInput {
  border: 1px solid #c63235 !important;
  background-color: #f7e2e1 !important;
  border-radius: 5px !important;
}
.errorInputbg {
  background-color: #f7e2e1 !important;
  border: 0px !important;
}
.inputCurrencySelect > .rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle .inputBordered {
  border: none !important;
  background-color: transparent !important;
  padding-block: 7px !important;
}

.rs-input {
  background-color: transparent;
}

.rs-input-group:not(.rs-input-group-inside) {
  border: none;
}

.tabbedNav.rs-nav > ul {
  justify-content: flex-start;
}

.rs-nav-tabs.rs-nav-horizontal
  .rs-nav-item.rs-nav-item-active
  > .rs-nav-item-content {
  background-color: white;
  color: #0b5cab;
}

.tabbedNav.rs-nav > ul > li > a {
  color: #000000;
  padding: 7.5px 11px !important;
}


@media screen and (max-width: 928px) {
  .tabbedNav.rs-nav > ul > li > a {
    color: #000000;
    padding:10px 8px !important
  }
  
}
.mobilenavbarContentMenu{
  border-bottom: 1px solid #e6e6e6;
}
.mobilenavbarContentMenu>ul>li>a{
  border-radius: 0px !important;
}
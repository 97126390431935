.footer {
  width: 100%;
  min-height: 470px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContent {
  height: 100%;
  width: 100%;
}

.emailSubscriptionOuterDiv {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.emailSubscription {
  height: 100%;
  width: 1220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerNavigationOuterDiv {
  width: 100%;
  min-height: 350px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerNavigation {
  height: 100%;
  width: 1220px;
  border: none;
  border-block-start: 1px solid #ffffff16;
  border-block-end: 1px solid #ffffff16;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.footerNavigationCol {
  min-width: 240px;
  height: 100%;
  padding-block: 40px;
}

.footerEndOuterDiv {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footerEnd {
  height: 100%;
  width: 1220px;
  display: flex;
  padding-block-start: 50px;
}

.footerEndContent {
  display: flex;
  width: 33.3%;
}

@media screen and (max-width: 1280px) {
  .emailSubscription {
    width: 95%;
  }
  .footerNavigation {
    width: 95%;
  }
  .footerEnd {
    width: 95%;
  }

  .footerNavigationCol {
    padding-block: 15px;
    min-width: 150px;
  }
}

.footerSelectPicker.rs-picker-select.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-top-end.rs-picker-has-value
  > a {
  background-color: transparent !important;
}

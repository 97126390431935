.custom-slider {
  width: 100% !important;
  height: 1200px !important;
}

.homepage-carousel-item {
  display: flex;
  justify-content: center;
  padding-block-start: 60px;
}

.homepage-carousel-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
}
.custom-slider > .rs-carousel-toolbar > ul > li {
  transform: translateX(-40%) !important;
}
.custom-slider > .rs-carousel-toolbar > ul > li > label {
  background-color: #ffffff;
}
.rs-carousel-label-wrapper input:checked ~ label {
  width: 30px !important;
  height: 18px !important;
}
@media screen and (max-width: 928px),screen and (max-height:900px) {
.homepage-carousel-title{
  font-size: 34px !important;
  line-height: 36px !important;
}.homepage-carousel-subtitle{
  font-size:24px !important;
}
}
.story {
  margin-top: 20px;
  padding-block: 30px;
  padding-inline: 10px;
  width: 100%;
  min-height: 450px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fabe46;

}

.storyItems {
  width: 1284px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap:10px
}
@media  screen and (max-width: 1284px){
  .storyItems {
    width:100% !important
  }
  .storyItem{
    width: 170px !important;
    margin-inline: 0px !important;
    height: 190px !important;
  }
  .story>.bigHeadingStyle{
    font-size: 18px !important;
}
.storyItem>div>.bigHeadingStyle{
    font-size: 20px !important;
}
}
@media  screen and (max-width: 380px){
  .storyItems {
    justify-content: center !important;
    align-items: center !important;
  }

}
.storyItem {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-inline: 3.8% 3.8%;
}

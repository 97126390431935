@media screen and (min-width: 1921px)
{
    .dx-column-lines{
        font-size: 16px !important;
    }
    .dx-datagrid-text-content{
        font-size: 18px !important;
    }
    .responsiveAutoCompleteValue{
        font-size: 20px !important;
    }
    .rs-auto-complete-item{
        font-size: 18px !important;
    }
    .sidebarNavigationComponent{
        .rs-steps-item-title{

            font-size: 20px !important;
        }
    }
    .registerResponsive{
 
        .formLabel{
            font-size: 17px !important;
        }
        textarea{
            font-size: 20px !important;
        }
        input{
            font-size: 20px !important;
        }
        label{        
            font-size: 17px !important;

        }
        .newBoldHeadingStyle{
            font-size: 32px !important;
        }
        .halfWidth>span,.datePickerComp>span{
            font-size: 17px !important;
        }
        .datePickerComp {
            .rs-picker-toggle-value{
                font-size: 20px !important;
            }
            .rs-picker-toggle-wrapper {
              height: 43px !important;
            }
          }
        .threeCol{
            margin-bottom: 10px !important;
            width: 48% !important;
        }
        .dividedRow {
            margin-top:10px !important;
            flex-wrap: wrap !important;
        }
        button{
            font-size: 20px !important;
        }
        .dividedRow>div>span{
            font-size: 17px !important;
        }
        .filterTagPicker {
            .rs-tag-text{
                font-size: 14px !important;
            }
            height: 43px !important;
            a > span {
              font-size: 22px !important;
            }
          }
        
    }
    .defaultResponsiveModal{
        .halfWidth>span{
            font-size: 17px !important;
        }
        label{
            font-size: 17px !important;
        }
        input{
            font-size: 20px !important;
        }
        button{
            font-size: 20px !important;
        }
    }
}
.baseScreen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.innerPage {
  /* Mozilla */
  height: -moz-calc(100% - 121px);
  /* WebKit */
  height: -webkit-calc(100% - 121px);
  /* Opera */
  height: -o-calc(100% - 121px);
  /* Standard */
  height: calc(100% - 121px);
  width: 100%;
  overflow: auto;
}

.innerPageContent {
  /* Mozilla */
  height: -moz-calc(100% - 73px);
  /* WebKit */
  height: -webkit-calc(100% - 73px);
  /* Opera */
  height: -o-calc(100% - 73px);
  /* Standard */
  height: calc(100% - 73px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.outerScreen {
  width: 100%;
  height: 100%;
}

.innerScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.innerScreenContent {
  width: 100%;
  height: max-content;
  display: flex;
}

.customSteps {
  padding-block-start: 30px;
  font-weight: 700;
  color: #181818;
}

.customSteps
  > .rs-steps-item.rs-steps-item-status-finish
  > .rs-steps-item-icon-wrapper {
  border-color: #37a75b;
}

.customSteps
  > .rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon)
  .rs-steps-item-icon::before {
  color: #37a75b;
}

.customSteps
  > .rs-steps-item.rs-steps-item-status-finish
  > .rs-steps-item-content {
  color: #37a75b;
}

.customSteps
  > .rs-steps-item.rs-steps-item-status-process
  > .rs-steps-item-icon-wrapper {
  border-color: #0b5cab;
  background-color: #0b5cab;
}

.customSteps
  > .rs-steps-item.rs-steps-item-status-process
  > .rs-steps-item-content {
  color: #0b5cab;
}

.customSteps
  > .rs-steps-item.rs-steps-item-status-wait
  > .rs-steps-item-icon-wrapper,
.rs-steps-item-content {
  border-color: #181818;
  color: #181818;
}

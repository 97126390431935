@media screen and (min-width: 1921px)
{
    .innerScreen{
        width: 99% !important;
    }
    .authPageImage{
        background-size: 75%, 100% !important;
        h1{
            font-size: 60px !important;
        }
        h4{
            font-size: 44px !important;
            line-height: 1.2 !important;
        }
    }
    .loginContent,.forgotPasswordContent{
        .standardText,.boldText{
            font-size: 22px !important;
        }
        .headingStyle{
            font-size: 24px !important;
            line-height: 1.2!important;
        }
        h4{
            font-size: 36px !important;
        }
        .bigRegularText{
            font-size: 24px !important;
        }
        label{
            font-size: 18px !important;
        }
        input{
            font-size: 20px !important;
        }
        button{
            font-size: 20px !important;
        }
    }

    .register-modal  {
        width: 1200px !important;

    }
    .register-modal > div > .rs-modal-content > div > .modalRsuiteBody {
        max-height: 1000px !important;
        min-height: 900px ;
        height: 750px !important;
      }
    .registrationTypeInnerDiv{
        height: 750px !important;
    }
    .registrationFromImage{
        background-size: 100% 100%;
    }
    .registerFormik{
        .halfWidth{
            width: 49% !important;
        }
    }
    .registerFormik,.registerFormik>form,.registerFormik>form>div{
        height: 100% !important;
    }
    .registerButtonGroup{
        button{
            font-size: 18px !important;
        }
    }
    .registrationFormDiv {
    label{
        font-size: 18px !important;
    }
    input{

        font-size: 20px !important;
    }
    }
}
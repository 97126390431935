
.borderedTable{
  margin-top: 10px;
  font-size: 13px;
  border-collapse: collapse;
  width: 100%;
  tr:nth-child(even){background-color:#f2f2f259;}

  tr:hover {background-color: rgb(231, 231, 231);}
  th {
      padding-top: 12px;
      padding-bottom: 12px;
      padding: 8px;
      text-align: left;
      border: 1px solid #c8c8c8;
      background-color:#c8c8c8;
       color: white;
      
      border-bottom: 1px solid #ddd;
    }
    td{
      border-block: 1px solid #ddd;
      padding: 8px;
    }
} 
.customTable{
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
    tr:nth-child(even){background-color:#f2f2f259;}
    tr:hover {background-color: rgb(231, 231, 231);}
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding: 8px;
        text-align: left;
        // border: 1px solid rgb(0, 163, 224);
        // background-color: rgb(0, 163, 224);
        // color: white;
        
        border-bottom: 1px solid #ddd;
      }
      td{
        border-block: 1px solid #ddd;
        padding: 8px;
      }
} 
.editFormOuterDiv{
  border: 1px dashed #ddd;
  padding:10px ;
}
.gridIcon{
  background-color: #37A75B;
  border-radius: 6px ;
  padding: 8px 10px;
}
.gridIconEdit{
  background-color:rgb(0, 163, 224);
  border-radius: 6px ;
  padding: 8px 10px;
}
.gridIconClose{
  background-color:transparent;
  border-radius: 6px ;
  color:#C63235;
  font-weight: 900;
  padding: 8px 10px;
}
.gridPosition{
  padding-block-start: 20px ;
  padding-inline-start: 7px ;
}

.rs-picker-select-menu-items{
  max-height: 200px !important;
}

@media screen and (max-width: 991px){
  .gridPosition{
    padding-block-start: 0px  ;
    padding-block-end: 6px;
    padding-inline-start: 0px ;
  }
  .smResponsiveButtonDiv{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
}

.gridButtonItem{
  margin-inline-end: 4px !important;
}
@media screen and (max-width: 40em) {
  .responsiveTable td.pivoted{
    padding-block-end:20px !important;
  }
  .responsiveTable tbody tr{
    border-color: #ddd !important;
  }
}

.gridButtonItem{
  background-color: transparent ;
  i{
    color:rgb(0, 163, 224);
  }
}

.gridColumnButtonContainer{
  display: flex
}

.editFormOuterDiv{
  margin-bottom: 10px;
  position:relative;
}
.gridUndoButton{
  top: -23px;
  right:-10px;
  position: absolute;

}


@media screen and (max-width: 2560px) and (min-width: 1924px) {
.borderedTable{
  font-size: 16px !important;
}  .xxsmallText{
  font-size: 14px !important;
}
}
@media screen and (max-width: 3840px) and (min-width: 2561px) {
  .borderedTable{
    font-size: 20px !important;
  }
  .xxsmallText{
    font-size: 16px !important;
  }
  }
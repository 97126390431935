.exampleDiv {
  width: 100%;
  height: 100%;
  padding-block: 15px;
  padding-inline: 6% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.divider {
  height: 1px;
  width: 100%;
  border: 1px solid;
  margin-block: 5px 20px;
}

.flexDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
  border-color: #0b827c !important;
  border: 2px solid;
  background-color: transparent;
}

.rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
  border-color: #0b827c;
}

.rs-radio-wrapper .rs-radio-inner::after {
  background-color: #0b827c;
}
.sidemenu-sidefilter-sidebar{
  
  background-color: rgb(252, 252, 252);
}
.sidemenu-dropdown{
  background-color: rgb(252, 252, 252);
  a{
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: #181818 !important;
    line-height: 24px;
    padding-left: 0px !important;
  }
  a:hover,a:active,a:focus{
    
  background-color: rgb(252, 252, 252);
  }
}

.move-button{
  display: block;
  width: 18%;
  position: fixed;
  z-index: 5;
  bottom :30px
}
.sticky-button{
  display: block;
  width: 18%;
  position: absolute;
  padding-block-end: 15px;
}



.mobile-search-sidebarfilter{
  display: none;
}
.mobile-search-sidebarfilter-drawer{
  display: none;
}

.responsivefilterButtonsOuterDiv{
  display:  none;
}
.filterButtonsOuterDiv{
  display: flex;
}
@media screen and (max-width: 928px) 
{
  .search-sidebarfilter-main-div{
    display: none  !important;
  }
  .mobile-search-sidebarfilter{
    display: block  !important;
  }
  .mobile-search-sidebarfilter-drawer{
    display: block !important;
  }
  .filterButtonsOuterDiv{
    display: none;
  }
  .responsivefilterButtonsOuterDiv{
    display: flex !important;
  }
}

@media screen and (max-height: 700px) and (max-width:  928px)
{
  .filterButtonsOuterDiv{
    display: none;
  }
  .responsivefilterButtonsOuterDiv{
    display: flex !important;
  }

}

@media screen and (max-height: 700px)
{
  .filterButtonsOuterDiv{
    display: none;
  }

}
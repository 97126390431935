.truckResultCard {
  height: max-content;
  width: 100%;
  background: #ffffff;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-block-end: 12px;
}

.truckResultCardMainDetails {
  /* Mozilla */
  width: -moz-calc(100% - 250px);
  /* WebKit */
  width: -webkit-calc(100% - 250px);
  /* Opera */
  width: -o-calc(100% - 250px);
  /* Standard */
  width: calc(100% - 250px);
  min-height: 77px;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colContent {
  padding-block-start: 25px;
}

.vehicleLogo {
  width: 70px;
  height: 70px;
  border-radius: 4.54545px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstCol {
  width: 12%;
  min-width: 60px;
  height: max-content;
}

.secondCol {
  width: 25%;
  min-width: 50px;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.thirdCol {
  width: 15%;
  min-width: 90px;
  height: max-content;
}

.forthCol {
  width: 25%;
  min-width: 145px;
  height: max-content;
  justify-content: center;
}

.fifthCol {
  width: 13%;
  min-width: 81px;
  height: max-content;
  justify-content: center;
}

.offerButton {
  padding-block: 5px;
  padding-inline: 7px;
  height: 30px;
  border: 1px solid #37a75b;
  border-radius: 30px;
  background-color: #f2fff6;
  color: #37a75b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  z-index: 10;
}

.offerButton:hover {
  background-color: #37a75b;
  color: #fff;
}

.extraInfos {
  width: 100%;
  border: none;
  border-block-start: 1px solid;
  padding-block: 12px;
  padding-inline: 15px;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
}

.extraInfosButton {
  width: 50px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-inline-end: 1px solid;
  transition: 0.3s;
}

.extraInfosButton:hover {
  background-color: #74747416 !important;
}

.extraDetails {
  width: 100%;
  height: 293px;
  padding-inline: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.extraDetailsContent {
  width: 100%;
  height: 225px;
  border-inline-start: 1px solid;
  border-inline-end: 1px solid;
  border-block-end: 1px solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #d9d9d9;
  background-color: white;
}

.extraDetailsFirstTab {
  width: 100%;
  height: 100%;
  padding-block: 14px;
  display: flex;
  justify-content: space-between;
}

.extraDetailsFirstTabFirstCol {
  height: 100%;
  width: 64%;
  display: flex;
  flex-direction: column;
}

.routeList {
  margin: 0;
  padding: 0;
}

.routeList > li {
  display: block;
  margin-block: 7px;
}

.extraDetailsFirstTabSecondCol {
  height: 100%;
  width: 31%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.truckRoute {
  width: 100%;
  height: 150px;
  padding: 12px;
}

.fadeColoredHeader {
  margin-block-end: 10px;
}

.truckIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 40px;
}

@media screen and (max-width: 1350px) {
  .driverInfos {
    width: auto;
    max-width: 115px;
    height: max-content;
    margin-inline-end: 0;
  }

  .locationInfos {
    width: auto;
    max-width: 185px;
    height: max-content;
    margin-inline-end: 0;
  }

  .dateInfos {
    width: auto;
    max-width: 215px;
    height: max-content;
    margin-inline-end: 0;
  }

  .amountInfos {
    width: auto;
    max-width: 137px;
    height: max-content;
    margin-inline-end: 0;
  }
}

.rateIcon {
  width: 38px;
  height: 38px;
  border-radius: 50px;
  border: 1px solid #e3e3e3;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media print {
  .tabbedNav.rs-nav > ul {
    height: 38px !important;
  }
}

.card-object-value{
  font-size: 12px !important;

}
@media screen and (max-width: 1200px) {
  .truckResultCardMainDetails{
    width: calc(100% - 180px) !important;
  }
  .card-offer-button{
    width: 80px !important;
  }
 
  .card-object{
    font-size: 11px !important;

  }
  .card-object-value{
    font-size: 11px !important;

  }
}


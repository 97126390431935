.verticalLoadCard {
  width: 283px !important;
  height: 465px;
  background: #ffffff;
  border-radius: 10px;
  margin-inline: 15px;
  /* Drop Shadow */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  transition: 0.5s ease-in-out;
  margin-block-start: 10px;
}
.mobil-extraDetailsContent{
  width: 100% !important;
  
}

.iconGroupItem{
  border: 1px solid #E6F7FE;
  background-color: #E6F7FE;
  width: 50px ;
  height: 50px;
  display: flex; 
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius:10px;
  font-size: 12px;
}

.iconGroup{
  width: 100% ;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap:5px;
}

.mobilCardButton{
  background-color: #E7F3F2;
}